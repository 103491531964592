import React, { useState } from 'react';
import styled from 'styled-components';
import { LabelStyled, SpanStyled } from '../../Styled/Elements';

const ImagePreview = ({ inputId, inputName }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const pictureImageTxt = "Choose an Image/File";

  const handleInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };

      reader.readAsDataURL(file);
    } else {
      setImageSrc(null);
    }
  };

  return (
    <ImageWrapper>
      <LabelStyled className="picture" htmlFor={inputId} tabIndex="0">
        <SpanStyled className="picture__image">
          {imageSrc ? <img src={imageSrc} alt="Selected" className="picture__img" /> : pictureImageTxt}
        </SpanStyled>
      </LabelStyled>
      <input type="file" name={inputName} id={inputId} onChange={handleInputChange} style={{ display: 'none' }} />
    </ImageWrapper>
  );
};

export default ImagePreview;

const ImageWrapper = styled.div`
  width: 100%;
  .picture {
    width: 100%;
    height: 150px;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray);
    border: 2px dashed currentcolor;
    border-radius: 8px;
    cursor: pointer;
    font-family: sans-serif;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
  }

  .picture:hover {
    color: var(--darkBlue);
    background: var(--white);
  }

  .picture:active {
    border-color: var(--purple);
    color: var(--purple);
    background: var(--white);
  }

  .picture:focus {
    color: var(--darkColor);
    background: var(--white);
  }
  .picture__image{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .picture__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
