import Dashboard from "../Components/dashboard-layout/Dashboard";
// import AboutIndex from "../Pages/aboutUs/AboutIndex";
import AboutIndexAdd from "../Pages/aboutUs/AboutIndexAdd";
import OurExpertise from "../Pages/aboutUs/OurExpertise";
import OurProcess from "../Pages/aboutUs/OurProcess";
import WhoAreWeAdd from "../Pages/aboutUs/WhoAreWeAdd";
import WhyChooseUs from "../Pages/aboutUs/WhyChooseUs";
import BannerAdd from "../Pages/home/BannerAdd";
import BannerEdit from "../Pages/home/BannerEdit";
import BannerIndex from "../Pages/home/BannerIndex";
import MaintainServices from "../Pages/services/MaintainServices";
import Services from "../Pages/services/Services";
import AllPortfolioAdd from "../Pages/portfolio/AllPortfolioAdd";
import AllPortfolioIndex from "../Pages/portfolio/AllPortfolioIndex";
import Partners from "../Pages/partners/Partners";
import PartnerAdd from "../Pages/partners/PartnerAdd";
import OurTeam from "../Pages/ourTeam/OurTeam";
import MaintainMembers from "../Pages/ourTeam/MaintainMembers";
import ClientTestimonials from "../Pages/clientTestimonials/ClientTestimonials";
import MaintainTestimonials from "../Pages/clientTestimonials/MaintainTestimonials";
import Technologies from "../Pages/technologies/Technologies";
import MaintainTechnologies from "../Pages/technologies/MaintainTechnologies";
import OtherSettings from "../Pages/otherSettings/OtherSettings";



export const routesAdminConfig = [
  {
    path: "/banner-slider",
    element: <BannerIndex />,
  },
  {
    path: "/banner-add",
    element: <BannerAdd />,
  },
  {
    path: "/banner-edit/:id",
    element: <BannerEdit />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/services-add",
    element: <MaintainServices />,
  },
  {
    path: "/service-edit/:id",
    element: <MaintainServices />,
  },
  {
    path: "/technologies",
    element: <Technologies />,
  },
  {
    path: "/add-technologies",
    element: <MaintainTechnologies />,
  },
  {
    path: "/edit-technologies/:id",
    element: <MaintainTechnologies />,
  },
  {
    path: "/our-team",
    element: <OurTeam />,
  },
  {
    path: "/teammember-add",
    element: <MaintainMembers />,
  },
  {
    path: "/teammember-edit/:id",
    element: <MaintainMembers />,
  },
  {
    path: "/aboutUs",
    element: <AboutIndexAdd />,
  },
  {
    path: "/who-we-are",
    element: <WhoAreWeAdd />,
  },
  {
    path: "/our-expertise",
    element: <OurExpertise />,
  },
  {
    path: "/why-choose-us",
    element: <WhyChooseUs />,
  },
  {
    path: "/our-process",
    element: <OurProcess />,
  },
  {
    path: "/portfolio",
    element: <AllPortfolioIndex />,
  },
  {
    path: "/portfolio-add",
    element: <AllPortfolioAdd />,
  },
  {
    path: "/portfolio-edit/:id",
    element: <AllPortfolioAdd />,
  },
  {
    path: "/partners",
    element: <Partners />,
  },
  {
    path: "/partners-add",
    element: <PartnerAdd />,
  },
  {
    path: "/partners-edit/:id",
    element: <PartnerAdd />,
  },
  {
    path: "/client-testimonials",
    element: <ClientTestimonials />,
  },
  {
    path: "/add-client-testimonials",
    element: <MaintainTestimonials />,
  },
  {
    path: "/edit-client-testimonials/:id",
    element: <MaintainTestimonials />,
  },
  {
    path: "/other-settings",
    element: <OtherSettings />,
  },

];
