import styled from "styled-components";

export const TopbarWrapper = styled.header`
//box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  // border-radius: 12px;
  background-color: var(--white);
  padding: 20px 20px 20px 268px;
  border-bottom: 1px solid var(--outlineC);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 102;
  transition: 0.3s;
  .btnlist {
    display: flex;
    align-items: center;
    gap: 20px;
    & > div > button {
      padding: 6px 10px;
    }
  }
  ul {
    & > li {
      position: relative;
      svg {
        top: 10px;
      }
      & > a {
        text-decoration: none;
        font-size: 14px;
        position: relative;
        color: var(--drColor);

        svg {
          [stroke] {
            //stroke: var(--drColor);
          }
        }
      }
      .red-dot:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--red);
        position: absolute;
        top: -4px;
        right: -1px;
        border: 2px solid var(--white);
      }
      margin-right: 24px;
      &:last-child {
        margin-right: 0px;
      }
      .active-dot::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--green2);
        border: 2px solid var(--white);
        position: absolute;
        top: 0px;
        left: 26px;
        z-index: 2;
      }
    }
    .userDD {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .ddLinkU {
      font-size: 14px;
      color: var(--grey);
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
  .dropMNListContainer {
    min-width: 320px;
    max-width: 320px;
  }
  .MNHeading {
    line-height: 20px;
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid var(--outlineC);
  }
  .changeButton {
    position: relative;
    top: 0;
    right: 0;
  }
  .userNameT {
    position: relative;
    color: var(--darkBlue);
    font-size: 14px;
    font-family: var(--medium);
    padding-right: 20px;
  
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: flex;
      width: 12px;
      height: 7px;
      background-image: url(../images/svg/dropArrow.svg);
      background-repeat: no-repeat;
    }
  }
`