import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BackButton, ButtonWithIcon } from "../../Styled/FormElements";
import { useNavigate } from "react-router-dom";
import { Box, HeadingStyled, LabelStyled } from "../../Styled/Elements";
import ImagePreview from "../../Components/img-preview/ImagePreview";

const PartnerAdd = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Row>
        <BackButton label="Add Partner" onClick={() => navigate("/partners")} />
      </Row>
      <Box className="my-4">
        <form>
          <Row>
            <Col lg={12} md={12}>
              <Row className="input-content-wrap">
                <Col lg={6} md={12}>
                  <LabelStyled className="label-heading">
                    Partner Name
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Partner's Name"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12}>
              <Row className="input-content-wrap">
                <Col lg={6} md={12}>
                  <LabelStyled className="label-heading">
                    Image Upload
                  </LabelStyled>
                  <ImagePreview inputId="AddPartner" inputName="AddPartner" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-end">
            <Col lg={2}>
              <ButtonWithIcon label="submit" />
            </Col>
          </Row>
        </form>
      </Box>
    </Container>
  );
};

export default PartnerAdd;
