import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, HeadingStyled, LazyImage } from '../../Styled/Elements'
import { Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../../Styled/FormElements'
import TableList from '../../Components/table/TableList'
import DeleteModel from '../../Components/models/DeleteModel'
import { TestimonialColumns } from '../../Components/table/TableContent'
import { useNavigate } from 'react-router-dom'
import { Author } from '../../Styled/AllImages'

const ClientTestimonials = () => {
    const navigate = useNavigate();
    const [showModal, setModal] = useState(false);
    const onDelete = () => {
        setModal(true);
    };
    const rows = [
        {
            id: "01",
            client_name: "Kevin",
            project_name: "PRM",
        },

    ];

    return (

        <HomeWrapper>
            <Container>
                <Row>
                    <Box className="common-main-heading" >
                        <HeadingStyled>Client's Testimonials</HeadingStyled>
                        <ButtonWithIcon label='Add Testimonial' onClick={() => navigate('/add-client-testimonials')} />
                    </Box>
                </Row>
                <Box className='my-4'>
                    <TableList
                        onDelete={onDelete}
                        options={["edit", "delete"]}
                        idIndex={1}
                        Edit='/edit-client-testimonials'
                        rows={rows}
                        columns={TestimonialColumns}
                    />
                </Box>
                <DeleteModel title="Team Member" setModal={setModal} showModal={showModal} />
            </Container>
        </HomeWrapper>
    )
}

export default ClientTestimonials

const HomeWrapper = styled.section`

`