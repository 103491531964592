import React from 'react'
import { TopbarWrapper } from './Styled'
import { Box, HeadingStyled, LazyImage, SpanStyled } from '../../Styled/Elements'
import { Dropdown } from 'react-bootstrap'

const Topbar = () => {
    return (
        <TopbarWrapper className='topBar'>
            <HeadingStyled className="mb-0" size="18px" weight="normal" color="var(--darkBlue)">
                Hi Yasir!
            </HeadingStyled>

            <Box className="btnlist">
                {/* <InputStyledWrap>
          <InputWithIcon type="text" placeholder="search" icon={SearchIcon} />
        </InputStyledWrap> */}
                {/* <ChangeButton /> */}
                <ul className="list-unstyled mb-0 d-flex align-items-center">
                    {/* <li>
                        <Dropdown>
                            <Dropdown.Toggle bsPrefix className="bg-transparent position-relative border-0 p-0">
                                <Box className="red-dot">
                                    <Message height="24px" width="24px" />
                                </Box>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropMNListContainer">
                                <HeadingStyled size="18px" family="var(--semiBold)" className="MNHeading" >
                                    Messages
                                </HeadingStyled>
                                {MessagesListArray.map((item, key) => (
                                    <MessagesList {...item} key={item.key} className="BankListing"
                                    />
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
                    {/* <li className="pt-1">
                        <Dropdown>
                            <Dropdown.Toggle bsPrefix className="bg-transparent position-relative border-0 p-0">
                                <Box className="red-dot">
                                    <Notification height="24px" width="24px" />
                                </Box>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropMNListContainer">
                                <HeadingStyled size="18px" family="var(--semiBold)" className="MNHeading"> Messages</HeadingStyled>
                                {NotificationsListArray.map((item, key) => (
                                    <NotificationsList  {...item}  key={item.key}  className="BankListing"/>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
                    <li>
                        <Dropdown>
                            <Dropdown.Toggle bsPrefix className="bg-transparent active-dot position-relative border-0 p-0 userDD">
                                <LazyImage className="rounded-circle" width="35px" height="35px" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80" />
                                <SpanStyled className="userNameT" color="var(--darkBlue)">Kevin Miller</SpanStyled>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                                <Dropdown.Item
                                    href="#!"
                                    className="d-flex align-items-center ddLinkU"
                                >
                                    {/* <UserIcon className='flip-item me-2' width='20px' height='20px' /> */}
                                    Profile
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#!" className="d-flex align-items-center ddLinkU">
                                    {/* <LogoutIcon className='flip-item me-2' width='20px' height='20px' /> */}
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
            </Box>

        </TopbarWrapper>
    )
}

export default Topbar