import React from 'react'
import styled from 'styled-components'
import Topbar from '../topbar/Topbar'
import { Box } from '../../Styled/Elements'
import { Route, Routes } from 'react-router-dom'
import { routesAdminConfig } from '../../Routes/AdminRoutes'
import { Sidebar } from '../sidebar/Sidebar'

const AdminLayout = () => {
    return (
        <LayoutWrapper className="d-flex flex-wrap">
            <Topbar />
            <Sidebar />
            <Box className="main-content-container pb-5">
                <Routes>
                    {routesAdminConfig.map((list, key) => (
                        <Route key={key} {...list} />
                    ))}
                </Routes>
            </Box>
        </LayoutWrapper>
    )
}

export default AdminLayout

const LayoutWrapper = styled.div`
background-color: var(--greyBg);
  overflow: hidden;
  //height: 100vh;
  padding: 0px 0px 0px 0px;
  .custom-scroll {
    overflow-x: auto;
    overflow-x: hidden;
    padding-top: 16px;
  }
  .main-content-container {
    width: 100%;
    padding: 80px 0 30px 248px;
    background-color: var(--greyBg);
    transition: 0.3s;
    min-height: calc(100vh - 130px);
  }

`
