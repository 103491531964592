export const BannerColumns = [
    {
        name: "id",
        label: "#",
    },
    {
        name: "Title",
        label: "Title",
    },
    {
        name: "SubHeading",
        label: "Sub Heading",
    },

    {
        name: "Heading",
        label: "Heading",
    },
    {
        name: "Number",
        label: "Number",
    },
    {
        name: "ButtonTitle",
        label: " Button Title",
    },

];
export const ServicesColumns = [
    {
        name: "id",
        label: "#",
    },
    {
        name: "Title",
        label: "Title",
    },
    {
        name: "description",
        label: "Description",
    },
];
export const OurTeamColumns = [
    {
        name: "id",
        label: "#",
    },
    {
        name: "member_Img",
        label: "Member Image",
    },
    {
        name: "member_name",
        label: "Member Name",
    },
    {
        name: "designation",
        label: "Designation",
    },
];
export const TestimonialColumns = [
    {
        name: "id",
        label: "#",
    },
    {
        name: "tech_img",
        label: "Technology Image",
    },
    {
        name: "tech_name",
        label: "Technology Name",
    },
];
export const AboutIndexColumns = [
    {
        name: "id",
        label: "#",
    },
    {
        name: "featureImg",
        label: " Feature Img",
    },

    {
        name: "Heading",
        label: "Heading",
    },
    {
        name: "ButtonTitle",
        label: " Button Title",
    },

];
export const PortfolioColumns = [
    {
        name: "id",
        label: "#",
    },
    {
        name: "featureImg",
        label: " Feature Img",
    },
    {
        name: "SelectedList",
        label: " Selected List",
    },

];
export const PartnerColumns = [
    {
        name: "id",
        label: "#",
    },
    {
        name: "featureLogo",
        label: " Logo",
    },

];