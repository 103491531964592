import { Dashboard, Report, Services, Setting } from "../../Styled/AllImages";

export const sidebarList = [
    {
        title: "Banner Sliders",
        icon: Dashboard,
        url: "/banner-slider",
    },
    {
        title: "Services",
        icon: Services,
        url: "/services",
    },
    {
        title: "Technologies",
        icon: Services,
        url: "/technologies",
    },
    {
        title: "About Us",
        icon: Report,
        submenu: [
            {
                title: "About Us",
                url: "/aboutUs",
            },
            {
                title: "Who We Are",
                url: "/who-we-are",
            },
            {
                title: "Our Expertise",
                url: "/our-expertise",
            },
            {
                title: "Why choose Us",
                url: "/why-choose-us",
            },
            {
                title: "Our Process",
                url: "/our-process",
            },
        ],
    },
    {
        title: "Our Team",
        icon: Services,
        url: "/our-team",
    },
    {
        title: "Testimonials",
        icon: Services,
        url: "/client-testimonials",
    },
    {
        title: "Portfolio",
        icon: Setting,
        url: "/portfolio",
    },
    {
        title: "Partners",
        icon: Setting,
        url: "/partners",
    },
    {
        title: "Other Settings",
        icon: Setting,
        url: "/other-settings",
    },

];