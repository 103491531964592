import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, HeadingStyled } from '../../Styled/Elements'
import { Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../../Styled/FormElements'
import TableList from '../../Components/table/TableList'
import DeleteModel from '../../Components/models/DeleteModel'
import { BannerColumns } from '../../Components/table/TableContent'
import { useNavigate } from 'react-router-dom'

const BannerIndex = () => {
    const navigate = useNavigate();
    const [showModal, setModal] = useState(false);
    const onDelete = () => {
        setModal(true);
    };
    const rows = [
        {
            id: "01",
            Title: "Android Development",
            SubHeading: "Internal",
            Heading: "Savannah Nguyen",
            Number: "0321-6547588",
            ButtonTitle: "Contact Us",
        },

    ];

    return (

        <HomeWrapper>
            <Container>
                <Row>
                    <Box className="common-main-heading" >
                        <HeadingStyled>Hero Section Slider</HeadingStyled>
                        <ButtonWithIcon label='Add' onClick={() => navigate('/banner-add')} />
                    </Box>
                </Row>
                <Box className='my-4'>
                    <TableList
                        onDelete={onDelete}
                        options={["edit", "delete"]}
                        idIndex={1}
                        Edit='banner-edit'
                        rows={rows}
                        columns={BannerColumns}
                    />
                </Box>
                <DeleteModel title="Banner Slider" setModal={setModal} showModal={showModal} />
            </Container>
        </HomeWrapper>
    )
}

export default BannerIndex


const HomeWrapper = styled.section`

`