import styled from "styled-components";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    ArrowDown,
    ArrowUp,
    Logo,
    MenuTrigger,
    MenuCross,
} from "../../Styled/AllImages";
import { SidebarStyled } from "./styled";
import { Box, SpanStyled } from "../../Styled/Elements";
import { sidebarList } from "./data";

export const Sidebar = () => {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(true);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        document.body.classList.toggle("bodyClosed");
    };
    const [activeSubMenu, setActiveSubMenu] = useState();
    const handleSubMenuToggle = (index) => {
        setActiveSubMenu(activeSubMenu === index ? null : index);
    };
    const handleMenuItemClick = (index) => {
        setActiveSubMenu(null);
    };

    const isSubMenuActive = (submenu) => {
        return submenu.some(subitem => location.pathname.includes(subitem.url));
    };

    return (
        <SidebarStyled className={`sidebar ${menuOpen ? "" : "menuClosed"}`}>
            <Box className="text-center">
                <Box className="logo-container">
                    <Logo className="logoSidebar" width="150px" height="auto" />
                    <MenuBnt onClick={toggleMenu}>
                        <MenuTrigger className="icon-trigger" />
                        <MenuCross className="cross" />
                    </MenuBnt>
                </Box>
                {/* <Box className="border-seprator"></Box> */}
                <Box className="sidebar-list custom-scroll">
                    <ul className="list-unstyled mb-0">
                        {sidebarList.map((item, key) => {
                            // const active = location.pathname.includes(item.url) ? 'active' : ''
                            // const active = location.pathname.includes(item.url) ? "active" : "";
                            const hasSubMenu = item.submenu && item.submenu.length > 0;
                            const active = hasSubMenu 
                                ? isSubMenuActive(item.submenu) 
                                : location.pathname.includes(item.url);
                            return (
                                <li key={item.title + key} className="mb-1">
                                    <Link
                                        to={item.url}
                                        className={`text-decoration-none d-flex align-items-center ${active ? "active" : ""}`}
                                        onClick={() => {
                                            handleMenuItemClick(key);
                                            hasSubMenu && handleSubMenuToggle(key);
                                        }}>
                                        <Box className="list-icon me-3">
                                            <item.icon />
                                        </Box>
                                        <Box>
                                            <SpanStyled>{item.title}</SpanStyled>
                                        </Box>
                                        {hasSubMenu && (
                                            <span className="ms-auto">
                                                {activeSubMenu === key ? <ArrowDown /> : <ArrowUp />}
                                            </span>
                                        )}
                                    </Link>
                                    {hasSubMenu && menuOpen && activeSubMenu === key && (
                                        <ul className="subMenu">
                                            {item.submenu.map((subitem, subkey) => (
                                                <li key={subitem.title + subkey} className="my-1">
                                                    <Link
                                                        to={subitem.url}
                                                        className={`text-decoration-none w-100 d-block ${location.pathname.includes(subitem.url) &&
                                                            "subActive"
                                                            }`}>
                                                        {subitem.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </Box>
            </Box>
            {/* <ul className="list-unstyled mb-0">
            {sidebarList.map((item, key) => {
              const active = location.pathname.includes(item.url)
                ? "active"
                : "";
              return (
                <li key={item.title + key} className="mb-1">
                  <Link
                    to={item.url}
                    className={`text-decoration-none d-flex align-items-center ${active}`}
                  >
                    <Box className="list-icon me-3">
                      <item.icon />
                    </Box>
                    <Box>
                      <SpanStyled>{item.title}</SpanStyled>
                    </Box>
                  </Link>
                </li>
              );
            })}
          </ul> */}
        </SidebarStyled>
    );
};
const MenuBnt = styled.button`
  padding: 0;
  border: none;
  background: transparent;
`;
