import React, { useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { Box } from "../app/Styled/Elements";
import { AuthRoutes } from "./Routes/AuthRoute";
import { Footer } from "./Components/footer/Footer";
import AdminLayout from "./Components/dashboard-layout/AdminLayout";


function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: "smooth" });
  }, [location]);

  return (
    <Box className="w-100">
      <Routes>
        {AuthRoutes.map((list, key) => (
          <Route key={key} {...list} />
        ))}
        <Route path="/*" element={<AdminLayout />} />
        <Route path="*" element={<Box>404 Not found</Box>} />
      </Routes>
      <Footer />
    </Box>
  );
}

export default App;
