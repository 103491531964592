import React from "react";
import styled from "styled-components";
import { Box, HeadingStyled, LabelStyled } from "../../Styled/Elements";
import { Col, Container, Row } from "react-bootstrap";
import ImagePreview from "../../Components/img-preview/ImagePreview";
import { BackButton, ButtonWithIcon } from "../../Styled/FormElements";
import { useLocation, useNavigate } from "react-router-dom";

const MaintainTestimonials = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HomeWrapper>
      <Container>
        <form>
          <Row>
            <BackButton
              label={`${
                location.pathname.includes("edit") ? "Edit" : "Add"
              } Testimonials`}
              onClick={() => navigate("/client-testimonials")}
            />
          </Row>

          <Box className="my-4">
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Upload Project Icon
                  </LabelStyled>
                  <ImagePreview inputId="ImageUpload" inputName="ImageUpload" />
                </Box>
              </Col>
            </Row>
            <Box>
              <HeadingStyled size="26px">English</HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Client Name
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Client's Name"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Project name
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Project Name"
                  />
                </Box>
              </Col>
              <Col lg={12} md={12}>
                <Row>
                  <Col lg={6} md={12}>
                    <Box className="input-content-wrap">
                      <LabelStyled className="label-heading">
                        Client Feedback
                      </LabelStyled>
                      <textarea
                        className="input-field"
                        rows="6"
                        cols="6"
                        type="text"
                        placeholder=" Enter The Feedback"
                      />
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Upload Client's Audio File
                  </LabelStyled>
                  <ImagePreview inputId="ImageUpload" inputName="ImageUpload" />
                </Box>
              </Col>
            </Row>
          </Box>
          <Box className="my-4">
            <Box>
              <HeadingStyled size="26px">Arabic</HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Client Name
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Client's Name"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Project name
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Project Name"
                  />
                </Box>
              </Col>
              <Col lg={12} md={12}>
                <Row>
                  <Col lg={6} md={12}>
                    <Box className="input-content-wrap">
                      <LabelStyled className="label-heading">
                        Client Feedback
                      </LabelStyled>
                      <textarea
                        className="input-field"
                        rows="6"
                        cols="6"
                        type="text"
                        placeholder=" Enter The Feedback"
                      />
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Upload Client's Audio File
                  </LabelStyled>
                  <ImagePreview inputId="ImageUpload" inputName="ImageUpload" />
                </Box>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={2}>
                <ButtonWithIcon label="submit" />
              </Col>
            </Row>
          </Box>
        </form>
      </Container>
    </HomeWrapper>
  );
};

export default MaintainTestimonials;

const HomeWrapper = styled.section``;
