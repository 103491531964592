import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Author, ButtonIcon, Email, Logo } from "../../../Styled/AllImages";
import { ButtonWithIcon, InputWithIcon } from "../../../Styled/FormElements";
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from "../../../Styled/Elements";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    return (
        <ContainerWrapper>
            <Container >
                <Row className="spacing">
                    <Col lg={6} md={12}>
                        <Logo />
                        <Box className="input-wrapper">
                            <Box className="heading">
                                <HeadingStyled className="">Wellcome Admin</HeadingStyled>
                                <TextStyled>Please enter your email and password to login</TextStyled>
                            </Box>
                            <InputWithIcon type='email' icon={Email} placeholder="Enter Your Email" />
                            <InputWithIcon type='number' icon={Email} placeholder="Enter Your Password" />
                            <ButtonWithIcon onClick={() => navigate("/banner-slider")} label='Login' />
                            <Box className="d-flex align-items-center justify-content-end">
                                <SpanStyled className='bgGradient cursor-pointer' onClick={() => navigate('reset-password')}>Forgot Password?</SpanStyled>
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={6} md={12} >
                        <Box className="d-flex align-items-center justify-content-center">
                            <LazyImage src={Author} />
                        </Box>
                    </Col>
                </Row>
            </Container>
        </ContainerWrapper>
    );
};
export default Login;

const ContainerWrapper = styled.section`

`