import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Author, Email, Logo } from "../../../Styled/AllImages";
import { ButtonWithIcon, InputWithIcon } from "../../../Styled/FormElements";
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from "../../../Styled/Elements";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const navigate = useNavigate();
    return (
        <ContainerWrapper>
            <Container >
                <Row className="spacing">
                    <Col lg={6} md={12}>
                        <Logo />
                        <Box className="input-wrapper">
                            <Box className="heading">
                                <HeadingStyled className="">Reset Password</HeadingStyled>
                                <TextStyled>Password reset link will be sent to your given email.</TextStyled>
                            </Box>
                            <InputWithIcon type='email' icon={Email} placeholder="Enter Your Email" />
                            <ButtonWithIcon label='Reset Password' />
                            <Box className="d-flex align-items-center justify-content-end">
                                <SpanStyled className='bgGradient cursor-pointer' onClick={() => navigate('/')} >Back to login</SpanStyled>
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={6} md={12} >
                        <Box className="d-flex align-items-center justify-content-center">
                            <LazyImage src={Author} />
                        </Box>
                    </Col>
                </Row>
            </Container>
        </ContainerWrapper>
    );
};
export default ResetPassword;

const ContainerWrapper = styled.section`

`