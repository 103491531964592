import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BackButton, ButtonWithIcon } from "../../Styled/FormElements";
import { Box, HeadingStyled, LabelStyled } from "../../Styled/Elements";
import ImagePreview from "../../Components/img-preview/ImagePreview";
import { MultiSelect } from "react-multi-select-component";
import AllPortfolioAddAr from "./AllPortfolioAddAr";

const options = [
  { label: "Laravell ", value: "laravell" },
  { label: "React ", value: "reactJs" },
  { label: " Swift", value: "swift" },
  { label: " Filter", value: "filter" },
];

const AllPortfolioAdd = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [listingText, setListingText] = useState([{ id: 0 }]);

  const handleAddList = (e) => {
    e.preventDefault();
    setListingText((prevList) => [...prevList, { id: prevList.length }]);
  };
  const handleRemoveList = (id) => {
    setListingText((prevList) =>
      prevList.filter((visitedLink) => visitedLink.id !== id)
    );
  };
  return (
    <Container>
      <Row className="pt-4 pb-2">
        <BackButton
          label="Add Portfolio"
          onClick={() => navigate("/portfolio")}
        />
      </Row>
      <Box className="my-4">
        <Box>
          <HeadingStyled size="24px">English Editor</HeadingStyled>
        </Box>
        <form>
          <Row>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">Select </LabelStyled>
                <div className="multi-select-container">
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                    className="multiselect-field"
                    disableSearch={false}
                  // valueRenderer={customValueRenderer}
                  />
                </div>
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">Select Eidt Technologies </LabelStyled>
                <div className="multi-select-container">
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                    className="multiselect-field"
                    disableSearch={false}
                  // valueRenderer={customValueRenderer}
                  />
                </div>
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">
                  Image Upload
                </LabelStyled>
                <ImagePreview
                  inputId="allPortfolioImg"
                  inputName="allPortfolioImg"
                />
              </Box>
            </Col>
          </Row>
          <Row>
            <HeadingStyled size="24px" className="my-4">
              Portfolio Detail
            </HeadingStyled>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">Title </LabelStyled>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Logo Title"
                />
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">Webiste Link </LabelStyled>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Webiste Link"
                />
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">Android Link </LabelStyled>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Android Link"
                />
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">Apple Link </LabelStyled>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Apple Links"
                />
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">
                  Scope
                </LabelStyled>
                <textarea
                  className="input-field"
                  rows="6"
                  cols="6"
                  type="text"
                  placeholder=" Enter The Description"
                />
              </Box>
            </Col>

            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">
                  Solution
                </LabelStyled>
                <textarea
                  className="input-field"
                  rows="6"
                  cols="6"
                  type="text"
                  placeholder=" Enter The Description"
                />
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">Logo</LabelStyled>
                <ImagePreview inputId="logo" inputName="logo" />
              </Box>
            </Col>
            <Col lg={6} md={12}>
              <Box className="input-content-wrap">
                <LabelStyled className="label-heading">
                  Detail Feature Image
                </LabelStyled>
                <ImagePreview inputId="DetailImg" inputName="DetailImg" />
              </Box>
            </Col>

          </Row>
          <Box>
            {listingText.map((list, index) => (
              <Row className="position-relative">
                <Col key={list.id} lg={12} md={12}>
                  <Box className="input-content-wrap">
                    <LabelStyled className="label-heading">
                      Challenges
                    </LabelStyled>
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Add Challenges List"
                    />
                  </Box>
                </Col>
                {index > 0 && ( // Conditional rendering for remove button
                  <Col lg={12} className="d-flex justify-content-end">
                    <button className="icon-remove addon-btn" onClick={() => handleRemoveList(list.id)}> &#10006; </button>
                  </Col>
                )}
              </Row>
            ))}
            <Box className="position-relative d-flex justify-content-end align-items-center gap-4">
              <ButtonWithIcon onClick={handleAddList} label="Add +" />
            </Box>
          </Box>

          <Row>
            <AllPortfolioAddAr />
            <Row className="justify-content-end">
              <Col lg={2}>
                <ButtonWithIcon label="submit" />
              </Col>
            </Row>
          </Row>
        </form>
      </Box>
    </Container>
  );
};

export default AllPortfolioAdd;
