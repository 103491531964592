import React from "react";
import { Box } from "../../Styled/Elements";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import TableComponents from "./TableComponents";

const TableList = ({
  columns,
  rows,
  type,
  onDelete,
  options,
  idIndex,
  onView,
  onEdit,
  value,
  Edit,
}) => {
  // const dispatch = useDispatch();
  return (
    <>
      <Box className="table-responsive custom-scroll overflow-x-auto">
        <TableStyled className="w-100 table">
          <thead>
            <tr>
              {columns.map((list, key) => (
                <th className="text-uppercase" key={key}>
                  {list.label}
                </th>
              ))}

              {options && options.length > 0 && (
                <th className="text-uppercase" style={{ width: "150px" }}>
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {rows.map((list, key) => (
              <tr key={key}>
                {columns.map(({ name, render }, key) =>
                  name === "actions" ? (
                    <ActionsStyled key={key}>
                      {render ? render(list[name], list) : list[name]}
                    </ActionsStyled>
                  ) : (
                    <td
                      key={key}
                      className={`${
                        list[name] &&
                        list[name].length > 30 &&
                        "TableColumnWidth"
                      }`}
                      title={list[name] && list[name]}
                    >
                      {render ? render(list[name], list) : list[name]}
                    </td>
                  )
                )}

                {options && options.length > 0 && (
                  <td>
                    {
                      <TableComponents
                        // onSetting={onSetting}
                        // onButton={onButton}
                        // onEdit={onEdit}
                        // {...(onView && { onView: () => onView(list) })}
                        // {...(onEdit && { onEdit: () => onEdit(list) })}
                        onDelete={onDelete}
                        type={type}
                        options={options}
                        index={idIndex}
                        Edit={Edit}
                      />
                    }
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </Box>
    </>
  );
};

export default TableList;

const TableStyled = styled.table`
  table-layout: auto;
  white-space: nowrap;
  th {
    color: var(--darkBlue);
    padding: 13px 12px;
    border: none;
    font-family: var(--semiBold);
    color: var(--grey-color);
    font-size: 12px;
    background-color: var(--greyBg);
  }
  tbody {
    tr {
      td {
        position: relative;
        color: var(--grey);
        font-size: 14px;
        padding: 10px 12px;
        border-bottom: 1px solid var(--outlineC);
        vertical-align: middle;
        .tblDbMdText {
          font-family: var(--medium);
          color: var(--darkBlue);
        }
      }
      &:last-child td {
        border-bottom: none;
      }
    }
  }
  tfoot {
    td {
      // border-top: 1px solid var(--grey-light);
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  gap: 2px;
  cursor: pointer;
`;
