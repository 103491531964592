import React from 'react'
import { Modal } from 'react-bootstrap'
import { Box, ButtonStyled, TextStyled } from '../../Styled/Elements'
import { DeleteIcon } from '../../Styled/AllImages'
import styled from 'styled-components'

const DeleteModel = ({ setModal, showModal, title }) => {
    return (
        <Modal centered show={showModal} onHide={() => setModal(false)} className="custom-action-modal">
            <DeleteWrapper className='del-container'>
                <Modal.Header className="justify-content-center gap-2">
                    <TextStyled className='m-0' color="var(--red)" family="var(--semiBold)" size="16px">
                        Delete {title}
                    </TextStyled>
                    <Box className='border-outlined-icon del-icon'>
                        <DeleteIcon width={16} />
                    </Box>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <TextStyled color="var(--gray)" className="text-center mt-2" size="15px">
                        Are you sure you want to delete {title}
                    </TextStyled>
                    <Box className="del-action">
                        <ButtonStyled onClick={() => setModal(false)} className=" del-btn del-cnl">
                            NO, Keep it
                        </ButtonStyled>
                        <ButtonStyled className="del-ok del-btn ">
                            Yes, Delete
                        </ButtonStyled>
                    </Box>
                </Modal.Body>
            </DeleteWrapper>
        </Modal>
    )
}

export default DeleteModel

const DeleteWrapper = styled.div`

.del-icon{
    &>svg{
        [fill]{
            fill:var(--red);
        }
    }
}

    .del-action{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        margin: 25px 0 0 0;
        .del-btn{
            height:43px;
        }
        .del-cnl{
            color:var(--gray);
            transition: all ease-in-out 0.4s;
            &:hover{
                background:transparent;
                border: 1px solid var(--gray);
            }
        }
        .del-ok{
            background:transparent;
            border: 1px solid var(--red);
            color:var(--red);
            transition: all ease-in-out 0.4s;
            &:hover{
                background:var(--red);
                border:none;
                color:var(--white);
            }
        }
    }
`