import Login from "../Pages/Auth/login/Login";
import ResetPassword from "../Pages/Auth/reset/ResetPassword";



export const AuthRoutes = [
  {
    path: "/",
    element: <Login />,
    exact: true,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
    exact: true,
  },

];
