import React from "react";
import styled from "styled-components";
import { Box, HeadingStyled, LabelStyled } from "../../Styled/Elements";
import { Col, Container, Row } from "react-bootstrap";
import ImagePreview from "../../Components/img-preview/ImagePreview";
import { BackButton, ButtonWithIcon } from "../../Styled/FormElements";
import { useLocation, useNavigate } from "react-router-dom";

const OtherSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HomeWrapper>
      <Container>
        <form>
          <Row>
            <HeadingStyled>Additional Info</HeadingStyled>
          </Row>

          <Box className="my-4">
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Email
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Contact Email"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Phone no
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="number"
                    placeholder="Enter Phone no."
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Address
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Address"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Location URL
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Location URL"
                  />
                </Box>
              </Col>
            </Row>
          </Box>
          <Box className="my-4">
            <Box>
                <HeadingStyled size="26px">
                    Social Links
                </HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Linkedin
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="number"
                    placeholder="Enter Linkedin URL"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Facebook
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="number"
                    placeholder="Enter Facebook URL"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Instagram
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="number"
                    placeholder="Enter Instagram URL"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Twitter
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="number"
                    placeholder="Enter Twitter URL"
                  />
                </Box>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={2}>
                <ButtonWithIcon label="submit" />
              </Col>
            </Row>
          </Box>
        </form>
      </Container>
    </HomeWrapper>
  );
};

export default OtherSettings;

const HomeWrapper = styled.section``;
