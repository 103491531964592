import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, HeadingStyled } from '../../Styled/Elements'
import { Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../../Styled/FormElements'
import TableList from '../../Components/table/TableList'
import DeleteModel from '../../Components/models/DeleteModel'
import { BannerColumns, ServicesColumns } from '../../Components/table/TableContent'
import { useNavigate } from 'react-router-dom'

const Services = () => {
    const navigate = useNavigate();
    const [showModal, setModal] = useState(false);
    const onDelete = () => {
        setModal(true);
    };
    const rows = [
        {
            id: "01",
            Title: "Android Development",
            description: "Turn your ideas into powerful mobile apps with our specialized development services",
        },

    ];

    return (

        <HomeWrapper>
            <Container>
                <Row>
                    <Box className="common-main-heading" >
                        <HeadingStyled>Services</HeadingStyled>
                        <ButtonWithIcon label='Add' onClick={() => navigate('/services-add')} />
                    </Box>
                </Row>
                <Box className='my-4'>
                    <TableList
                        onDelete={onDelete}
                        options={["edit", "delete"]}
                        idIndex={1}
                        Edit='service-edit'
                        rows={rows}
                        columns={ServicesColumns}
                    />
                </Box>
                <DeleteModel title="Service" setModal={setModal} showModal={showModal} />
            </Container>
        </HomeWrapper>
    )
}

export default Services

const HomeWrapper = styled.section`

`