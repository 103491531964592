import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Box, HeadingStyled, LazyImage } from '../../Styled/Elements';
import { ButtonWithIcon } from '../../Styled/FormElements';
import TableList from '../../Components/table/TableList';
import { PortfolioColumns } from '../../Components/table/TableContent';
import DeleteModel from '../../Components/models/DeleteModel';
import { DemoImg } from '../../Styled/AllImages';


const PortfolioIndex = () => {
    const navigate = useNavigate();
    const [showModal, setModal] = useState(false);
    const onDelete = () => {
        setModal(true);
    };
    const rows = [
        {
            id: "01",
            featureImg: <LazyImage className='img-view' src={DemoImg} />,
            Heading: "Savannah Nguyen",
            SelectedList: "All , Wbsites, Mobile Apps",
        },

    ];
    return (
        <Container>
            <Row className='pt-4 pb-2'>
                <Box className="common-main-heading" >
                    <HeadingStyled>All Portfolio </HeadingStyled>
                    <ButtonWithIcon label='Add' onClick={() => navigate('/portfolio-add')} />
                </Box>
            </Row>
            <Box className='my-4'>
                <TableList
                    onDelete={onDelete}
                    options={["edit", "delete"]}
                    idIndex={1}
                    Edit='portfolio-edit'
                    rows={rows}
                    columns={PortfolioColumns}
                />
            </Box>
            <DeleteModel title="All Portfolio" setModal={setModal} showModal={showModal} />
        </Container>
    )
}

export default PortfolioIndex