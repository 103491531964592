import styled from "styled-components";
import { TextStyled } from "../../Styled/Elements";

export const Footer = () => {
    const date_year = new Date();
    return (
        <FooterStyled className="footer">
            <TextStyled size="16px" color="var(--white)" weight={400}>
                &copy; {date_year.getFullYear()} <span>Fowl.</span> All rights reserved.
            </TextStyled>
        </FooterStyled>
    );
};
const FooterStyled = styled.footer`
background: var(--purple);
text-align: center;
margin: auto;
position: fixed;
bottom: 0;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 15px 0 15px 0;
    &>p{
        margin:0;
        // padding-left:200px;
        
    }
}

`;