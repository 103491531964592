import React, { useState } from "react";
import styled from "styled-components";
import { Box, HeadingStyled, LabelStyled, Span } from "../../Styled/Elements";
import { Col, Container, Row } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import ImagePreview from "../../Components/img-preview/ImagePreview";
import {
  BackButton,
  ButtonWithIcon,
} from "../../Styled/FormElements";
import { useLocation, useNavigate } from "react-router-dom";

const MaintainServices = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const options = [
    { label: "Laravel", value: "laravel" },
    { label: "React", value: "react" },
    { label: "Flutter", value: "flutter" },
    { label: "Swift", value: "swift" },
    { label: "Kotlin", value: "kotlin" },
  ];

  const [selected, setSelected] = useState([]);

  const handleRemove = (item) => {
    setSelected(selected.filter((i) => i.value !== item.value));
  };

  const customValueRenderer = () => {
    if (selected.length === 0) {
      return <Span>Select...</Span>;
    }
    return (
      <div className="selected-pills">
        {selected.map((item) => (
          <div key={item.value} className="pill">
            {item.label}
            <span
              className="pill-remove"
              onClick={() => handleRemove(item)}
            >
              ×
            </span>
          </div>
        ))}
      </div>
    )
  }

  return (
    <HomeWrapper>
      <Container>
        <form>
          <Row>
            <BackButton
              label={`${
                location.pathname.includes("edit") ? "Edit" : "Add"
              } Service`}
              onClick={() => navigate("/services")}
            />
          </Row>

          <Box className="my-4">
            <Box>
              <HeadingStyled size="26px">English</HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">Title</LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter The Title"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Description
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Description"
                  />
                </Box>
              </Col>
              <Col lg={12} md={12}>
                <Row>
                  <Col lg={6} md={12}>
                    <Box className="input-content-wrap">
                      <LabelStyled className="label-heading">
                        Select Technologies
                      </LabelStyled>
                      <div className="multi-select-container">
                        <MultiSelect
                          options={options}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select"
                          className="multiselect-field"
                          disableSearch={false}
                          valueRenderer={customValueRenderer}
                        />
                      </div>
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Image Upload
                  </LabelStyled>
                  <ImagePreview inputId="ImageUpload" inputName="ImageUpload" />
                </Box>
              </Col>
            </Row>
          </Box>
          <Box className="my-4">
            <Box>
              <HeadingStyled size="26px">Arabic</HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">Title</LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter The Title"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Description
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Description"
                  />
                </Box>
              </Col>
              <Col lg={12} md={12}>
                <Row>
                  <Col lg={6} md={12}>
                    <Box className="input-content-wrap">
                      <LabelStyled className="label-heading">
                        Select Technologies
                      </LabelStyled>
                      <div className="multi-select-container">
                        <MultiSelect
                          options={options}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select"
                          className="multiselect-field"
                          disableSearch={false}
                          valueRenderer={customValueRenderer}
                        />
                      </div>
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Image Upload
                  </LabelStyled>
                  <ImagePreview
                    inputId="ImageUploadAr"
                    inputName="ImageUploadAr"
                  />
                </Box>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={2}>
                <ButtonWithIcon label="submit" />
              </Col>
            </Row>
          </Box>
        </form>
      </Container>
    </HomeWrapper>
  );
};

export default MaintainServices;

const HomeWrapper = styled.section`
`;
