import React from "react";
import styled from "styled-components";
import { Box, HeadingStyled, LabelStyled } from "../../Styled/Elements";
import { Col, Container, Row } from "react-bootstrap";
import ImagePreview from "../../Components/img-preview/ImagePreview";
import { BackButton, ButtonWithIcon } from "../../Styled/FormElements";
import { useLocation, useNavigate } from "react-router-dom";

const MaintainTechnologies = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HomeWrapper>
      <Container>
        <form>
          <Row>
            <BackButton
              label={`${
                location.pathname.includes("edit") ? "Edit" : "Add"
              } Technology`}
              onClick={() => navigate("/technologies")}
            />
          </Row>

          <Box className="my-4">
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Upload Icon
                  </LabelStyled>
                  <ImagePreview inputId="ImageUpload" inputName="ImageUpload" />
                </Box>
              </Col>
            </Row>
            <Box>
              <HeadingStyled size="26px">English</HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Technology Name
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Member's Name"
                  />
                </Box>
              </Col>
            </Row>
          </Box>
          <Box className="my-4">
            <Box>
              <HeadingStyled size="26px">Arabic</HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                  Technology Name
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Member's Name"
                  />
                </Box>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={2}>
                <ButtonWithIcon label="submit" />
              </Col>
            </Row>
          </Box>
        </form>
      </Container>
    </HomeWrapper>
  );
};

export default MaintainTechnologies;

const HomeWrapper = styled.section``;
