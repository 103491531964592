import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage } from '../../Styled/Elements'
import { ButtonWithIcon } from '../../Styled/FormElements'
import { useNavigate } from 'react-router-dom'
import TableList from '../../Components/table/TableList'
import { PartnerColumns } from '../../Components/table/TableContent'
import { DemoImg } from '../../Styled/AllImages'
import DeleteModel from '../../Components/models/DeleteModel'

const Partners = () => {
    const navigate = useNavigate();
    const [showModal, setModal] = useState(false);
    const onDelete = () => {
        setModal(true);
    };
    const rows = [
        {
            id: "01",
            featureLogo: <LazyImage className='img-view' src={DemoImg} />,
        },

    ];
    return (

        <Container>
            <Row>
                <Box className="common-main-heading" >
                    <HeadingStyled> Partners</HeadingStyled>
                    <ButtonWithIcon label='Add' onClick={() => navigate('/partners-add')} />
                </Box>
            </Row>
            <Box className='my-4'>
                <TableList
                    onDelete={onDelete}
                    options={["edit", "delete"]}
                    idIndex={1}
                    Edit='partners-edit'
                    rows={rows}
                    columns={PartnerColumns}
                />
            </Box>
            <DeleteModel title="Partner" setModal={setModal} showModal={showModal} />
        </Container>
    )
}

export default Partners