import React from 'react'
import styled from 'styled-components'
import { Box, HeadingStyled, LabelStyled } from '../../Styled/Elements'
import { Col, Container, Row } from 'react-bootstrap'
import ImagePreview from '../../Components/img-preview/ImagePreview'
import { BackButton, ButtonWithIcon } from '../../Styled/FormElements'
import { useNavigate } from 'react-router-dom'

const BannerAdd = () => {
    const navigate = useNavigate();
    return (

        <HomeWrapper>
            <Container>
                <Row>
                    <BackButton label='Add Hero Section Slider' onClick={() => navigate('/banner-slider')} />
                </Row>

                <Box className='my-4'>
                    <Box>
                        <HeadingStyled size='26px'>English</HeadingStyled>
                    </Box>
                    <form>
                        <Row>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Title</LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Title' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Sub Heading </LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Sub Heading' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'> Heading </LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Heading' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'> Button Title </LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter Button Title' />
                                </Box>
                            </Col>

                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                    <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Image Upload</LabelStyled>
                                    <ImagePreview inputId="ImageUpload" inputName="ImageUpload" />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'> Change Number </LabelStyled>
                                    <input className='input-field' type="number" placeholder='Change Number' />
                                </Box>
                            </Col>

                        </Row>
                    </form>
                </Box>
                <Box className='my-4'>
                    <Box>
                        <HeadingStyled size='26px'>Arabic</HeadingStyled>
                    </Box>
                    <form>
                        <Row>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Title</LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Title' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Sub Heading </LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Sub Heading' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'> Heading </LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Heading' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'> Button Title </LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter Button Title' />
                                </Box>
                            </Col>

                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                    <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Image Upload</LabelStyled>
                                    <ImagePreview inputId="ImageUploadAr" inputName="ImageUploadAr" />
                                </Box>
                            </Col>
                        </Row>
                        <Row className='justify-content-end'>
                            <Col lg={2}>
                                <ButtonWithIcon label='submit' />
                            </Col>
                        </Row>
                    </form>
                </Box>
            </Container>

        </HomeWrapper>
    )
}

export default BannerAdd


const HomeWrapper = styled.section`
`