import React, { useState } from "react";
import styled from "styled-components";
import { Box, HeadingStyled, LabelStyled } from "../../Styled/Elements";
import { Col, Container, Row } from "react-bootstrap";
import ImagePreview from "../../Components/img-preview/ImagePreview";
import { ButtonWithIcon } from "../../Styled/FormElements";

const AboutIndexAdd = () => {
  const [rows, setRows] = useState([{ id: 0 }]); // Initial state with one row

  const handleAddRow = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setRows((prevRows) => [...prevRows, { id: prevRows.length }]);
  };

  const handleRemoveRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  return (
    <AboutAddWrapper>
      <form>
        <Container>
          <Row>
            <HeadingStyled>Our Story </HeadingStyled>
          </Row>
          <Box className="my-4">
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    Feature Image{" "}
                  </LabelStyled>
                  <ImagePreview
                    inputId="featureImage"
                    inputName="featureImage"
                  />
                </Box>
              </Col>
            </Row>
            <Box>
              {rows.map((row, index) => (
                <Row className="position-relative" key={row.id}>
                  <Col lg={6} md={12}>
                    <Box className="input-content-wrap">
                      <LabelStyled className="label-heading">
                        Social Icons {index + 1}
                      </LabelStyled>
                      <ImagePreview
                        inputId={`socialIcons${row.id}`}
                        inputName={`socialIcons${row.id}`}
                      />
                    </Box>
                  </Col>
                  <Col lg={6} md={12}>
                    <Box className="input-content-wrap">
                      <LabelStyled className="label-heading">
                        Add Link
                      </LabelStyled>
                      <input
                        className="input-field"
                        type="text"
                        placeholder="Add Social Link"
                      />
                    </Box>
                  </Col>
                  {index > 0 && ( // Conditional rendering for remove button
                    <Col lg={12} className="d-flex justify-content-end">
                      <button
                        className="icon-remove addon-btn"
                        onClick={() => handleRemoveRow(row.id)}
                      >
                        {" "}
                        &#10006;{" "}
                      </button>
                    </Col>
                  )}
                </Row>
              ))}
              <Box className="position-relative d-flex justify-content-end align-items-center gap-4">
                <ButtonWithIcon onClick={handleAddRow} label="Add +" />
              </Box>
            </Box>
            <Box>
              <HeadingStyled size="26px">English</HeadingStyled>
            </Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">Title</LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter The Title"
                  />
                </Box>
              </Col>

              <Col lg={6} md={12}>
                <Box className="input-content-wrap">
                  <LabelStyled className="label-heading">
                    {" "}
                    Button Title{" "}
                  </LabelStyled>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter Button Title"
                  />
                </Box>
              </Col>
            </Row>
          </Box>
          <Box className="my-4">
            <Box>
              <HeadingStyled size="26px">Arabic</HeadingStyled>
            </Box>
            <form>
              <Row>
                <Col lg={6} md={12}>
                  <Box className="input-content-wrap">
                    <LabelStyled className="label-heading">Title</LabelStyled>
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Enter The Title"
                    />
                  </Box>
                </Col>

                <Col lg={6} md={12}>
                  <Box className="input-content-wrap">
                    <LabelStyled className="label-heading">
                      {" "}
                      Button Title{" "}
                    </LabelStyled>
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Enter Button Title"
                    />
                  </Box>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col lg={2}>
                  <ButtonWithIcon label="submit" />
                </Col>
              </Row>
            </form>
          </Box>
        </Container>
      </form>
    </AboutAddWrapper>
  );
};

export default AboutIndexAdd;

const AboutAddWrapper = styled.section``;
