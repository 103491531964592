import React, { useState } from 'react'
import { Box, HeadingStyled, LabelStyled } from '../../Styled/Elements'
import { Col, Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../../Styled/FormElements'
import ImagePreview from '../../Components/img-preview/ImagePreview'


const OurProcess = () => {

    const [rows, setRows] = useState([{ id: 0 }]); // Initial state with one row

    const handleAddRow = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setRows(prevRows => [...prevRows, { id: prevRows.length }]);
    };

    const handleRemoveRow = (id) => {
        setRows(prevRows => prevRows.filter(row => row.id !== id));
    };

    const [arRows, setArRows] = useState([{ id: 0 }]); // Initial state with one row

    const handleAddArRow = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setArRows(prevRows => [...prevRows, { id: prevRows.length }]);
    };

    const handleRemoveArRow = (id) => {
        setRows(prevRows => prevRows.filter(row => row.id !== id));
    };

    return (

        <Container>
            <Row>
                <HeadingStyled>Our Process</HeadingStyled>
            </Row>
            <Box className='my-4'>
                <Box>
                    <HeadingStyled size='26px'>English</HeadingStyled>
                </Box>
                <form>
                    <Row>

                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Heading</LabelStyled>
                                <input className='input-field' type="text" placeholder='Enter The Title' />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                            </Box>
                        </Col>
                    </Row>
                    <Box>
                        {rows.map((row, index) => (
                            <Row className='position-relative' key={row.id}>
                                <Col lg={6} md={12}>
                                    <Box className='input-content-wrap'>
                                        <LabelStyled className='label-heading'>Image Heading</LabelStyled>
                                        <input className='input-field' type="text" placeholder='Enter Image Heading' />
                                    </Box>
                                </Col>
                                <Col lg={6} md={12}>
                                    <Box className='input-content-wrap'>
                                        <LabelStyled className='label-heading'>Image Upload {index + 1}</LabelStyled>
                                        <ImagePreview inputId={`processImg${row.id}`} inputName={`processImg${row.id}`} />
                                    </Box>
                                </Col>
                                {index > 0 && ( // Conditional rendering for remove button
                                    <Col lg={12} className="d-flex justify-content-end">
                                        <button className="icon-remove addon-btn" onClick={() => handleRemoveRow(row.id)}> &#10006; </button>
                                    </Col>
                                )}
                            </Row>
                        ))}
                        <Box className='position-relative d-flex justify-content-end align-items-center gap-4'>
                            <ButtonWithIcon onClick={handleAddRow} label='Add +' />
                        </Box>
                    </Box>
                </form>
            </Box>
            <Box className='my-4'>
                <Box>
                    <HeadingStyled size='26px'>Arabic</HeadingStyled>
                </Box>
                <form>
                    <Row>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Heading</LabelStyled>
                                <input className='input-field' type="text" placeholder='Enter The Title' />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Image Heading</LabelStyled>
                                <input className='input-field' type="text" placeholder='Enter Image Heading' />
                            </Box>
                        </Col>
                    </Row>
                    {/* <Box>
                        {arRows.map((row, index) => (
                            <Row className='position-relative' key={row.id}>
                                <Col lg={6} md={12}>
                                    <Box className='input-content-wrap'>
                                        <LabelStyled className='label-heading'>Image Heading</LabelStyled>
                                        <input className='input-field' type="text" placeholder='Enter Image Heading' />
                                    </Box>
                                </Col>
                                <Col lg={6} md={12}>
                                    <Box className='input-content-wrap'>
                                        <LabelStyled className='label-heading'>Image Upload {index + 1}</LabelStyled>
                                        <ImagePreview inputId={`processImg${row.id}`} inputName={`processImg${row.id}`} />
                                    </Box>
                                </Col>
                                {index > 0 && ( // Conditional rendering for remove button
                                    <Col lg={12} className="d-flex justify-content-end">
                                        <button className="icon-remove addon-btn" onClick={() => handleRemoveArRow(row.id)}> &#10006; </button>
                                    </Col>
                                )}
                            </Row>
                        ))}
                        <Box className='position-relative d-flex justify-content-end align-items-center gap-4'>
                            <ButtonWithIcon onClick={handleAddArRow} label='Add +' />
                        </Box>
                    </Box> */}

                    <Row className='justify-content-end'>
                        <Col lg={2}>
                            <ButtonWithIcon label='submit' />
                        </Col>
                    </Row>

                </form>
            </Box>
        </Container>
    )
}

export default OurProcess

