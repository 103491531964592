import React from 'react'
import styled from 'styled-components'
import { Box, HeadingStyled, LabelStyled } from '../../Styled/Elements'
import { Col, Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../../Styled/FormElements'

const WhoAreWeAdd = () => {



    return (

        <WhoAddWrapper>
            <Container>
                <Row>
                    <HeadingStyled>Who We Are </HeadingStyled>
                </Row>
                <Box className='my-4'>
                    <Box>
                        <HeadingStyled size='26px'>English</HeadingStyled>
                    </Box>
                    <form>
                        <Row>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                    <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Another Paragraph</LabelStyled>
                                    <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Heading</LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Title' />
                                </Box>
                            </Col>
                        </Row>
                    </form>
                </Box>
                <Box className='my-4'>
                    <Box>
                        <HeadingStyled size='26px'>Arabic</HeadingStyled>
                    </Box>
                    <form>
                        <Row>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                    <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Another Paragraph</LabelStyled>
                                    <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='input-content-wrap'>
                                    <LabelStyled className='label-heading'>Heading</LabelStyled>
                                    <input className='input-field' type="text" placeholder='Enter The Title' />
                                </Box>
                            </Col>
                        </Row>
                        <Row className='justify-content-end'>
                            <Col lg={2}>
                                <ButtonWithIcon label='submit' />
                            </Col>
                        </Row>

                    </form>
                </Box>
            </Container>

        </WhoAddWrapper>
    )
}

export default WhoAreWeAdd


const WhoAddWrapper = styled.section`
`