import React, { useState } from 'react'
import { Box, HeadingStyled, LabelStyled } from '../../Styled/Elements'
import { Col, Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../../Styled/FormElements'
import { MultiSelect } from "react-multi-select-component";

const options = [
    { label: "Laravell ", value: "laravell" },
    { label: "React ", value: "reactJs" },
    { label: " Swift", value: "swift" },
    { label: " Filter", value: "filter" },
];
const optionsAr = [
    { label: "Laravell ", value: "laravell" },
    { label: "React ", value: "reactJs" },
    { label: " Swift", value: "swift" },
    { label: " Filter", value: "filter" },
];

const OurExpertise = () => {
    const [selected, setSelected] = useState([]);
    const [selectedAr, setSelectedAr] = useState([]);



    return (

        <Container>
            <Row>
                <HeadingStyled>Our Expertise </HeadingStyled>
            </Row>
            <Box className='my-4'>
                <Box>
                    <HeadingStyled size='26px'>English</HeadingStyled>
                </Box>
                <form>
                    <Row>

                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Heading</LabelStyled>
                                <input className='input-field' type="text" placeholder='Enter The Title' />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Select </LabelStyled>
                                <MultiSelect
                                    options={options}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Select"
                                />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                            </Box>
                        </Col>

                    </Row>
                </form>
            </Box>
            <Box className='my-4'>
                <Box>
                    <HeadingStyled size='26px'>Arabic</HeadingStyled>
                </Box>
                <form>
                    <Row>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Heading</LabelStyled>
                                <input className='input-field' type="text" placeholder='Enter The Title' />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Select </LabelStyled>
                                <MultiSelect
                                    options={optionsAr}
                                    value={selectedAr}
                                    onChange={setSelectedAr}
                                    labelledBy="Select"
                                />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='input-content-wrap'>
                                <LabelStyled className='label-heading'>Paragraph</LabelStyled>
                                <textarea className='input-field' rows="6" cols="6" type="text" placeholder=' Enter The Paragraph' />
                            </Box>
                        </Col>

                    </Row>
                    <Row className='justify-content-end'>
                        <Col lg={2}>
                            <ButtonWithIcon label='submit' />
                        </Col>
                    </Row>

                </form>
            </Box>
        </Container>
    )
}

export default OurExpertise

